._dot {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
}
._inline-dot {
  display: inline-block;
}

._round-button {
  border-radius: 50% !important;
}
.progress--height-2 {
  height: 5px;
}
.text-middle {
  vertical-align: middle !important;
}
.btn-hover {
  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
}
