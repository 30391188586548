@include keyframes(zoomOutUp) {
  40% {
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    opacity: 1;
    @include transform(scale3d(.475, .475, .475) translate3d(0, 60px, 0));
  }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    opacity: 0;
    @include transform(scale3d(.1, .1, .1) translate3d(0, -2000px, 0));
    @include transform-origin(center bottom);
  }
}

@mixin zoomOutUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(zoomOutUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
