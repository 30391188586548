@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin keyframeMaker($name) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
}
