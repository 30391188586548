.tab-border{
    border:1px dashed #ebedf2!important;
    margin: 30px 0!important;
}


.toggle-hidden::after {
    content: none;
    display: none !important;
  }
  .dropdown-toggle.btn {
    padding-right: 12px;
  }

span._r_block-dot{
    display: block;
    margin: 2px 0;
}

._r_btn{
    border:1px solid #e8ecfa;
}

._r_drop_right{
    padding-right: 14px!important;
}