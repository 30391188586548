@include keyframes(slideOutRight) {
	0% {
  	@include transform(translateX(0));
  }
	100% {
  	opacity: 0;
  	@include transform(translateX(2000px));
  }
}

@mixin slideOutRight($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(slideOutRight);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
