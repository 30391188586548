.react-tour {
  .eTpeTG {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 9px;
    height: 9px;
    color: #5e5e5e;
  }
}
