.search-ui {
  position: fixed;
  background: $background;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0.75rem 1.5rem 1.5rem 4.5rem;
  z-index: 99999999;
  display: none;
  &.open {
    display: block;
    @include slideInDown($duration: 0.3s, $delay: 0);
  }
  .search-header {
    .logo {
      object-fit: contain;
      height: 64px;
      width: auto;
    }
  }
  input.search-input {
    font-size: 4rem;
    font-weight: 600;
    border: 0;
    margin-bottom: 1.5rem;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: $gray-400;
    }
  }
  .search-title {
    margin-bottom: 1.25rem;
    span {
      font-weight: 600;
    }
  }
}

@media (max-width: 767px) {
  .search-ui {
    padding: 1rem;
  }
}
