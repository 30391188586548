.sidebar-panel {
  height: 100vh;
  position: fixed;
  top: 0;
  left: -240px;
  width: 240px;
  z-index: 1000;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  .brand {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 20px;
    }
  }
  .sidebar-compact-switch {
    position: relative;
    // top: 26px;
    // right: 16px;
    width: 24px;
    height: 12px;
    border-radius: 10px;
    border: 1px solid $gray-600;
    transition: all 0.3s ease-in;
    cursor: pointer;
    span {
      position: absolute;
      left: 1px;
      top: 1px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $gray-500;
      transition: all 0.3s ease-in;
    }
  }
  .scroll-nav {
    height: calc(100vh - 64px);
  }
  .app-user {
    .app-user-photo {
      img {
        height: 72px;
        width: 72px;
        border-radius: 50%;
        border: 1px solid $gray-200;
        padding: 4px;
      }
    }
    .app-user-name {
      display: block;
      font-weight: 300;
      margin-bottom: 0.5rem;
      i {
        font-size: 12px;
      }
    }
    .app-user-control {
      margin-bottom: 1rem;
      a {
        color: $foreground;
        i {
          font-size: 16px;
          height: 20px;
          width: 20px;
          line-height: 20px;
          display: inline-block;
        }
      }
    }
  }
  // side-nav

  .side-nav {
    .icon-menu-title,
    .main-menu-title {
      color: $gray-600;
      font-size: 12px;
      padding: 16px 0 16px 16px;
      display: block;
    }
    .icon-menu {
      // padding: 8px;
      .icon-menu-item {
        padding: 0 24px;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        a {
          i {
          }
        }
      }
    }

    .main-menu {
      .main-menu-title {
      }
      ul {
        padding: 12px;
        li {
          list-style: none;
          a {
            color: $foreground;
            display: flex;
            align-items: center;
            height: 48px;
            padding: 0px 15px;
            .nav-icon {
              margin-right: 10px;
            }
            .item-name {
              text-transform: capitalize;
            }
            .spacer {
              margin: auto;
            }
            &:hover {
              text-decoration: none;
            }
          }
          &.dropdown {
            > .sub-menu {
              max-height: 0;
              overflow: hidden;
              transition: all 0.4s ease-in;
              a {
                height: 44px;
                padding-left: 50px;
              }
            }
            &.open {
              > .sub-menu {
                max-height: 1000px;
              }
            }
          }
        }
      }
    }
  }
}

// side-nav-close
.app-admin-wrap.sidebar-full {
  &.sidebar-closed {
    .sidebar-panel {
      left: -240px;
    }
    .main-content-wrap {
      width: 100%;
    }
  }
  .sidebar-panel {
    width: 240px;
    left: 0px;
  }
  .main-content-wrap {
    width: calc(100% - 240px);
    padding: 1rem 2rem 0rem;
  }
}

.app-admin-wrap.sidebar-compact {
  // &.sidebar-compact-open {
  .sidebar-panel {
    width: 75px;
    left: 0;
    .app-user,
    .icon-menu-title,
    .main-menu-title,
    .item-badge,
    .item-name {
      display: none;
    }
    .sidebar-compact-switch {
      display: none;
    }
    .icon-menu-item {
      flex-direction: column;
      padding: 0;
      a {
        width: 100%;
        display: contents;
        i {
          padding: 5px;
          font-size: 30px;
        }
        &:hover {
          cursor: pointer;
          padding: 0px 10px;
        }
      }
    }
    .main-menu {
      ul {
        li {
          a {
            // padding: 0 8px;
            i {
              padding: 0;
            }
          }
        }
      }
      .dropdown {
        display: none;
      }
    }
  }
  .main-content-wrap {
    width: calc(100% - 65px);
    padding: 1rem 2rem 0;
  }

  // .sidebar-panel{
  //     width: 240px;
  //     left: 0px;
  // }
  .main-content-wrap {
    // width: calc(100% - 240px);
    .main-header {
      height: 64px;
    }
  }
}
.main-content-wrap.sidebar-full-z-index {
  width: calc(100% - 65px) !important;
  z-index: -100;
}

ul.metismenu {
  position: relative;
}
.Ul_li--hover::before {
  content: "";
  background-color: $primary;
  position: absolute;
  height: 47px;
  width: 225px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  opacity: 0.1;
  left: -233px;
  transition: all 400ms ease;
}

.Ul_li--hover:hover::before {
  left: 0px;
  transition: all 300ms ease;
  z-index: -1;
}

// .Ul_li--hover::after{
//     background-color: #639;
//     content: "";
//     position: absolute;
//     width: 225px;
//     border-top-right-radius: 25px;
//     border-bottom-right-radius: 25px;
//     height: 47px;
//     opacity: 0.1;

// }

// sidebar-arrow-icon
.metismenu .has-arrow:after {
  right: 2em !important;
  opacity: 0.6 !important;
  width: 0.6em !important;
  height: 0.6em !important;
  border-width: 2px 0 0 2px !important;
}
.comp {
  position: absolute;
  top: 69px;
  right: -166px;
}

// sidebar--->arrow icon remove-class and add-class
.has-arrow.without-after-element:after {
  content: none !important;
}
