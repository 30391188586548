@include keyframes(zoomOutLeft) {
  40% {
    opacity: 1;
    @include transform(scale3d(.475, .475, .475) translate3d(42px, 0, 0));
  }
  100% {
    opacity: 0;
    @include transform(scale(.1) translate3d(-2000px, 0, 0));
    @include transform-origin(left center);
  }
}

@mixin zoomOutLeft($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(zoomOutLeft);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
