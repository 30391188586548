.ul-b4-display__info-1 { 
    margin-bottom: 60px;
    
}
.ul-b4-display__info-1 p{
   font-size:14px;
   color: $gray-600;
  
}

.ul-b4-display__table {
    margin-top: 20px;
    // padding: 35px;
}
.ul-b4-display__table tr th{
    font-size: 17px;
}
.ul-b4-display__table tr td{
    font-size: 14px;
}
.ul-display__print ul li code{
    font-size:15px;
}
.ul-display__margin{
    margin: 40px 0;
}
.ul-display__paragraph{
     font-size: 14px;
}